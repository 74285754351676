import React from "react";
import styled from "@emotion/styled";

import {
    WppTopbar,
    WppButton,
    WppTypography,
    WppIconDownload,
    WppActionButton,
} from "@platform-ui-kit/components-library-react";
import {useNavigate} from "react-router-dom";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {useQuery} from "@tanstack/react-query";
import {IngestionGrid} from "../IngestionGrid/IngestionGrid";
import {getColumnConfigForTable} from "./data-ingestion-grid.config";
import {DataIngestionGridConstants} from "./DataIngestionGridConstants";
import Sidepanel from "./Sidepanel";
import ErrorMessageComponent  from "../../DataProcessing/Dashboard/ErrorMessageComponent";

const CreateButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

function TableRenderer({tableConfig}) {
    const {data, status} = useQuery({
        queryKey: [tableConfig.queryKey],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post(
                EndPoints[tableConfig.apiEndPoint]
            );
           
            return data;
        },
    });

    if(data){
        if(tableConfig.queryKey === 'weightMetadataFilesInfo'){
            localStorage.setItem('weightMetadataFilesInfo', JSON.stringify(data));
        }
    //     if(tableConfig.queryKey === 'weight_metadata_statistics'){
    //         localStorage.setItem('weight_metadata_statistics', JSON.stringify(data));
    // }
}
    return (
        <div>
            <div
                style={{
                    margin: `1rem 0`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <WppTypography type="l-body">{tableConfig.label}</WppTypography>
            </div>
            <IngestionGrid
                columns={getColumnConfigForTable(tableConfig.columnConfig)}
                data={data}
                status={status}
            />
        </div>
    );
}

const DataIngestion = ({ingestionType}) => {
    const workflowConfig = DataIngestionGridConstants[ingestionType];
    const navigate = useNavigate();

    const renderTables = () => {
        if (workflowConfig.dataTables && workflowConfig.dataTables.length !== 0) {
            return workflowConfig.dataTables.map((tableConfig, index) => (
                <TableRenderer key={index} tableConfig={tableConfig}/>
            ));
        } else {
            return (
                <div>
                    <div
                        style={{
                            margin: `1rem 0`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <WppTypography type="l-body"></WppTypography>
                    </div>
                </div>
            );
        }
    };

    const renderButtons = () => {
        if (
            !workflowConfig ||
            !workflowConfig.buttons ||
            workflowConfig.buttons.length === 0
        ) {
            return null;
        }

        return (
            <CreateButtonContainer style={{margin: "1rem"}}>
                {workflowConfig.buttons.map((buttonConfig, index) => (
                    <WppButton
                        style={{margin: "1rem 1rem"}}
                        key={index}
                        onClick={() => {
                            navigate(buttonConfig.navigateTo);
                        }}
                    >
                        {buttonConfig.label}
                    </WppButton>
                ))}
            </CreateButtonContainer>
        );
    };

    return (
        <div>
                    <div id ="test"> <ErrorMessageComponent/></div>
        <div
            style={{display: "grid", gridTemplateColumns: "0fr 1fr", gap: "1rem"}}
        >
          
            <div>
                <Sidepanel/>
            </div>
           
            <div>
                <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                    <div slot="app">
                        <WppTypography type="l-midi" style={{width: "24rem"}}>
                            {workflowConfig.welcomeLabel}
                        </WppTypography>
                    </div>
                </WppTopbar>
                <div style={{margin: "2rem"}}>
                    {renderButtons()}
                    {renderTables()}
                </div>
            </div>
        </div>
        </div>
    );
};

export default DataIngestion;
function onDownloadClick(card: any): void {
   console.log("onDownloadClick"+card);
}

