import React, { useState, useEffect } from "react";
import { WppAccordion, WppSpinner, WppTypography } from "@platform-ui-kit/components-library-react";
import { MetadataDiffGrid } from "../MetadataIngestion/MetadataDiffGrid";
import styles from "../MetadataIngestion/new-metadata-ingestion-workflow.module.scss";
import { AG_GRID_CELL_STYLE } from "../../../constants/ag-grid-defaults";
// import { data2api } from "./CombinedWeightDiffStep";
import { useQuery } from "@tanstack/react-query";
import { AudienceOriginRequest, EndPoints } from "../../../api/audience-origin";


// Adjusting the DiffItem interface for this example
interface DiffItem {
    file: string;
    op: "add" | "remove" | "replace";
    path: string;
    newValue?: string;
    oldValue?: string;
}

interface YamlDiffData {
    content?: DiffItem[];
    files?: string[];
    code?: string;
    message?: string[];  // Array of error messages
}

interface AdditionalYamldiffStepProps {
    gcsFileLocation2: string;
    survey: string;
    onSuccess: (success: boolean) => void;
}

// Adjusting the MetadataDiffSheetItemBase interface
interface MetadataDiffSheetItemBase {
    Role: string;
    "Column Width": string;
    Alignment: string;
    Variable: string;
    file: string;
    op: string;
    path: string;
    newValue?: string;
    oldValue?: string;
    "Write Format": string;
    "Measurement Level": string;
    Position: string;
    "Print Format": string;
}
 let data2api = null;

const useCombinedMetadataDiff = ( gcsFileLocation2, survey) => {
    return useQuery(
        ["combinedMetadataDiff", gcsFileLocation2, survey],
        async () => {
            const [response ] = await Promise.all([
                
                
               AudienceOriginRequest.get(`${EndPoints.NetyamldataDiff}?fileName=${gcsFileLocation2}&survey=${survey}`).catch((error) => {
                throw {secondError:error};
                data2api=response.data
               }),
            ]);
           
            return { data:response.data  };
           
        },
        {
            retry: false,
        }
    );
};



const columnDefs = [
    { headerName: "File", field: "file", sortable: true, cellStyle: AG_GRID_CELL_STYLE, maxWidth: 200 },
    { headerName: "Op", field: "op", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
    { headerName: "Path", field: "path", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
];

const modifyColumnDefs = [
    ...columnDefs,
    { headerName: "NewValue", field: "newValue", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
    { headerName: "OldValue", field: "oldValue", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
];

// Helper function to convert DiffItem to MetadataDiffSheetItemBase
const adaptDiffItem = (item: DiffItem): MetadataDiffSheetItemBase => ({
    ...item,
    Role: "Data", 
    "Column Width": "auto", 
    Alignment: "left", 
    Variable: item.path, 
    "Write Format": "Default",  
    "Measurement Level": "Level 1",  
    Position: "Top",  
    "Print Format": "Standard",
});

export const AdditionalYamldiffStep: React.FC<AdditionalYamldiffStepProps> = ({ 
    gcsFileLocation2,
    survey, 
    onSuccess 
}) => {
    const { status, error,data, isFetching } = useCombinedMetadataDiff( gcsFileLocation2, survey);
    const [isAddedOpen, setIsAddedOpen] = useState(false);
    const [isModifiedOpen, setIsModifiedOpen] = useState(false);
    const [isDeletedOpen, setIsDeletedOpen] = useState(false);
    const [diffData, setDiffData] = useState<YamlDiffData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
   // const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const initializeData = () => {
            try {
                console.log("API Response: ", data2api); 

                // if (!data2api) {
                //     throw new Error("No data available");
                // }

            
                if (data2api.code && data2api.message) {
                    const errorMessage = data2api.message.join(" "); 
                    console.error("API Error: ", errorMessage); 
                   // setError(errorMessage);
                    setIsLoading(false);
                    onSuccess(false);
                    return;
                }

                setDiffData(data2api);
                setIsLoading(false);
                onSuccess(true);
            } catch (err) {
                console.error("Error initializing data:", err);
                //setError(err instanceof Error ? err.message : "An error occurred loading the data");
                setIsLoading(false);
                onSuccess(false);
            }
        };

        initializeData();
    }, [onSuccess]);

    const filterData = (opType: "add" | "remove" | "replace"): DiffItem[] => {
        try {
            if (!diffData?.content) {
                return [];
            }
            return diffData.content.filter(item => item.op === opType);
        } catch (err) {
            console.error(`Error filtering ${opType} data:`, err);
            return [];
        }
    };

    const addData = filterData("add");
    const deleteData = filterData("remove");
    const modifyData = filterData("replace");

    const gridTemplate = (
        label: string, 
        gridData: DiffItem[], 
        columnDefs: any[]
    ) => {
        const handleAccordionChange = (e: CustomEvent) => {
            const isExpanded = e.detail.expanded;
            if (label.includes("Added")) setIsAddedOpen(isExpanded);
            if (label.includes("Modified")) setIsModifiedOpen(isExpanded);
            if (label.includes("Deleted")) setIsDeletedOpen(isExpanded);
        };

        // Convert the data to the expected format
        const adaptedData = gridData.map(adaptDiffItem);

        return (
            <WppAccordion 
                size="l"
                expanded={label.includes("Added") ? isAddedOpen : 
                         label.includes("Modified") ? isModifiedOpen : 
                         label.includes("Deleted") ? isDeletedOpen : false}
                onWppChange={handleAccordionChange}
            >
                <WppTypography type="l-body" slot="header">{label}</WppTypography>
                <div style={{ width: '100%', height: '100%' }}>
                    {gridData.length > 0 ? (
                        <MetadataDiffGrid data={adaptedData} columns={columnDefs} />
                    ) : (
                        <WppTypography type="l-body">No Data</WppTypography>
                    )}
                </div>
            </WppAccordion>
        );
    };

    const getFileName = (file: string): string => {
        try {
            return file.split("/").pop() || "";
        } catch {
            return file;
        }
    };
    const getErrorMessage = (error) => {
        return error?.secondError?.response?.data?.message ;
    };

   
   

    return (
        <div style={{ overflow: "scroll", height: "100%", width: "100%" }}>
        {isFetching && <WppSpinner size="m" />}
        {error && (
            <WppTypography>
                <strong>Warning: </strong>
                {getErrorMessage(error)}
            </WppTypography>
        )}
        {data && !error && (
        <div style={{ display: 'block', overflow: "scroll", height: "100%", width: "100%" }}>
            {isLoading ? (
                <div className={styles.centerContent}>
                    <WppSpinner size="m" />
                </div>
            ) : (
                <div>
                    <div style={{ margin: "2rem" }}>
                        {diffData?.files?.map((file, index) => (
                            <div key={index} style={{ marginBottom: "0.5rem" }}>
                                <WppTypography type="l-midi">
                                    {index === 0 ? "Old File (Processed):" : "New File (Pre-Processed):"}
                                </WppTypography>
                                <WppTypography type="l-body" className={styles.highlightText}>
                                    {getFileName(file)}
                                </WppTypography>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginTop: "1rem", marginLeft: "2rem", marginRight: "3rem" }}>
                        <div style={{ marginBottom: '20px' }}>
                            {gridTemplate(`Added (${addData.length})`, addData, columnDefs)}
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            {gridTemplate(`Modified (${modifyData.length})`, modifyData, modifyColumnDefs)}
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            {gridTemplate(`Deleted (${deleteData.length})`, deleteData, columnDefs)}
                        </div>
                    </div>
                </div>
            )}
        </div>
        )}
        </div>
        
    );
};
