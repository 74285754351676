import React, { Component } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "reactflow/dist/style.css";
import "./App.css";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { setViewerRole } from "./utility/user-actions";
import AllureDockerSigIn from "./components/AllureDockerSigIn/AllureDockerSigIn";
import AllureDockerMainContainer from "./containers/AllureDockerMainContainer/AllureDockerMainContainer";
import AllureDockerProject from "./containers/AllureDockerProject/AllureDockerProject";
import AllureDockerReportFullView from "./components/AllureDockerReportFullView/AllureDockerReportFullView";
import AllureDockerNotFound from "./components/AllureDockerNotFound/AllureDockerNotFound";
import AllureDockerErrorPage from "./components/AllureDockerErrorPage/AllureDockerErrorPage";
import axios from "./api/axios-allure-docker";
import AllureDockerHomePage from "./components/AllureDockerHomePage/AllureDockerHomePage";
import AllureDockerDashboard from "./components/AllureDockerDashboard/AllureDockerDashboard";
import HomePage from "./components/Dashboard/HomePage";
import { DataProcessingDashboard } from "./components/DataProcessing/Dashboard";
import HomeLayout from "./components/Dashboard/HomeLayout";
import { MetadataIngestion } from "./components/DataProcessing/MetadataIngestion";
import { MetadataIngestionWorkflow } from "./components/DataProcessing/MetadataIngestion/MetadataIngestionWorkflow";
import { RawDataIngestionWorkflow } from "./components/DataProcessing/RawDataIngestion/RawDataIngestionWorkflow";
import {
  QuestionnaireComparePage,
  QuestionnaireHomePage,
} from "./questionnarie/QuestionnaireHome";
import { AscriptionDataIngestionWorkflow } from "./components/DataProcessing/AscriptionDataIngestion/AscriptionDataIngestionWorkflow";
import { redirect } from "./utility/navigate";
import { QuestionnaireBuilder } from "./questionnarie/QuestionnaireBuilder";
import QuestionnairePreviewerWidget from "./questionnarie/QuestionnaireBuilder/QuestionnairePreviewerWidget";
import DataIngestion from "./components/DataProcessing/Dashboard/DataIngestion";
import { NetDataIngestionWorkflow } from "./components/DataProcessing/NetVariableIngestion/NetDataIngestionWorkflow";
import { NetCalculationWorkflow } from "./components/DataProcessing/NetVariableIngestion/NetCalculationWorkflow";
import { NetMetadataIngestionWorkflow } from "./components/DataProcessing/NetVariableIngestion/NetMetadataIngestionWorkflow";
import { NetCalcTestRunWorkflow } from "./components/DataProcessing/NetVariableIngestion/NetCalcTestRunWorkflow";
import { WeightConfigIngestionWorkflow } from "./components/DataProcessing/WeightVariableIngestion/WeightConfigIngestionWorkflow";
import { WeightCalcSnowflakeWorkflow } from "./components/DataProcessing/WeightVariableIngestion/WeightCalcSnowflakeWorkflow";
import { WeightCalcMarketFilesWorkflow } from "./components/DataProcessing/WeightVariableIngestion/WeightCalcMarketFilesWorkflow";
import { WeightCalcGlobalFilesWorkflow } from "./components/DataProcessing/WeightVariableIngestion/WeightCalcGlobalFilesWorkflow";
import { VariableOrderUploadWorkflow } from "./components/DataProcessing/MiscWorkflow/VariableOrderUploadWorkflow";
import { ExportMarketWorkflow } from "./components/DataProcessing/ExportWorkflow/ExportMarketWorkflow";
import { ExportGlobalWorkflow } from "./components/DataProcessing/ExportWorkflow/ExportGlobalWorkflow";
import { ExportGlobalDecodedWorkflow } from "./components/DataProcessing/ExportWorkflow/ExportGlobalDecodedWorkflow";
import { BatchExportWorkflow } from "./components/DataProcessing/ExportWorkflow/BatchExportWorkflow";
import { ExportSPSSWorkflow} from "./components/DataProcessing/ExportWorkflow/ExportSPSSWorkflow";
import {DataFixWorkflow} from "./components/DataProcessing/MiscWorkflow/DataFixWorkflow";
import {NetMetaFileUploadWorkflow} from "./components/DataProcessing/NetVariableIngestion/NetMetaFileUploadWorkflow";
// import QuestionnaireHomeHiddenPage from "./questionnarie/QuestionnaireHome/QuestionnaireHomeHiddenPage";
import { AllureDockerFilesWorkflow } from "./components/AllureDockerHomePage/AllureDockerFilesWorkflow";

const autoHideDurationSuccessAlert = 3000;
const autoHideDurationErrorAlert = 6000;
const autoHideDurationInfoAlert = 3000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export class App extends Component {
  state = {
    darkState: false,
    isLogoutNeeded: false,
    isSignInAnOption: false,
    isLoginRequired: null,
    error: null,
    projectSelected: "",
    apiAlert: {
      severity: "info",
      show: false,
      message: "",
      duration: 0,
    },
  };

  componentDidMount() {
    this.isLoginRequired();
    this.recoverTheme();
  }

  isAdminEndpointAccessible = () => {
    return axios
      .post("/send-results")
      .then((response) => {
        return response.status !== 401;
      })
      .catch((error) => {
        return error.status !== 401;
      });
  };

  isLoginRequired = () => {
    this.setState({ error: null });
    // temp state settings for iap testing

    axios
      .get("/config")
      .then(async (response) => {
        const isSecurityEnabled = response.data.data.security_enabled;
        let isLogoutNeeded = false;
        let isSignInAnOption = false;
        if (isSecurityEnabled === 1) {
          isLogoutNeeded = true;
          const isMakeViewerEndpointsPublic =
            response.data.data.make_viewer_endpoints_public;
          if (
            isMakeViewerEndpointsPublic === 1 &&
            !(await this.isAdminEndpointAccessible())
          ) {
            setViewerRole();
            isLogoutNeeded = false;
            isSignInAnOption = true;
          }
        } else {
          localStorage.removeItem("expirationDate");
          localStorage.removeItem("roles");
        }
        this.setState({
          isLoginRequired: false,
          isLogoutNeeded: isLogoutNeeded,
          isSignInAnOption: isSignInAnOption,
        });
      })
      .catch((error) => {
        if (error.redirect) {
          this.setState({ isLoginRequired: true, isLogoutNeeded: false });
        }
        this.setState({
          error: error,
        });
        // this.setState({
        //   isLoginRequired: true,
        //   isLogoutNeeded: true,
        //   isSignInAnOption: true,
        // });
      });
  };

  recoverTheme = () => {
    const darkState = localStorage.getItem("darkState");
    this.setState({ darkState: darkState === "true" });
  };

  handleThemeChange = () => {
    const darkState = !this.state.darkState;
    this.setState({ darkState: darkState });
    localStorage.setItem("darkState", String(darkState));
  };

  setAPIAlert = (severity, message, show) => {
    const apiAlert = { ...this.state.apiAlert };
    apiAlert.severity = severity;
    apiAlert.show = show;
    apiAlert.message = message;
    if (severity === "success") {
      apiAlert.duration = autoHideDurationSuccessAlert;
    }
    if (severity === "error") {
      apiAlert.duration = autoHideDurationErrorAlert;
    }
    if (severity === "info") {
      apiAlert.duration = autoHideDurationInfoAlert;
    }
    this.setState({ apiAlert: apiAlert });
  };

  getProjects = () => {
    axios
      .get("/projects")
      .then((response) => {
        const projects = response.data.data.projects;
        this.setState({
          projects: response.data.data.projects,
          projectSelected: null,
        });
        if (projects.length !== 0) {
          this.selectProject(Object.keys(projects)[0]);
        }
      })
      .catch((error) => {
        redirect(error);
        this.setAPIAlert("error", error.message, true);
      });
  };

  selectProject = (projectId) => {
    this.setState({ projectSelected: projectId });
  };

  render() {
    const mainPrimaryColor = this.state.darkState ? "#0d47a1" : "#2196f3";
    const mainSecondaryColor = this.state.darkState ? "#1e88e5" : "#0d47a1";
    const palletType = this.state.darkState ? "dark" : "light";
    const darkTheme = createTheme({
      palette: {
        mode: palletType,
        primary: {
          main: mainPrimaryColor,
        },
        secondary: {
          main: mainSecondaryColor,
        },
      },
    });

    let switchRouter;
    if (this.state.error) {
      switchRouter = <AllureDockerErrorPage error={this.state.error} />;
    }

    if (this.state.isLoginRequired !== null) {
      if (this.state.isLoginRequired) {
        switchRouter = (
          <Routes>
            <Route path="/*" element={<Navigate to="/signin" replace />} />
            <Route
              path="/signin"
              element={
                <AllureDockerSigIn isLoginRequired={this.isLoginRequired} />
              }
            />
            <Route element={<Navigate to="/signin" />} />
          </Routes>
        );
      } else {
        let signInAnOption;
        if (this.state.isSignInAnOption) {
          signInAnOption = (
            <React.Fragment>
              <Route
                path="/signin"
                element={
                  <AllureDockerSigIn
                    isLoginRequired={this.isLoginRequired}
                    isHomeAnOption={!this.state.isSignInAnOption}
                  />
                }
              />
            </React.Fragment>
          );
        } else {
          signInAnOption = (
            <Route path="/signin" element={<Navigate to="/" replace />} />
          );
        }

        switchRouter = (
          <Routes>
            <Route path="/" element={<Navigate to="/" replace />} />
            <Route path="/*" element={<HomeLayout />}>
              <Route index element={<HomePage />} />

              <Route path="data-processing/*">
                <Route index element={<DataProcessingDashboard />} />
                <Route
                  path="metadata-ingestion"
                  element={<MetadataIngestion />}
                />
                <Route
                  path="metadata-ingestion/new"
                  element={<MetadataIngestionWorkflow />}
                />
                <Route
                  path="raw-data-ingestion"
                  element={
                    <DataIngestion ingestionType="rawDataIngestionDashboard" />
                  }
                />
                <Route
                  path="raw-data-ingestion/new"
                  element={<RawDataIngestionWorkflow />}
                />
                <Route
                  path="asc-data-ingestion"
                  element={
                    <DataIngestion ingestionType="ascDataIngestionDashboard" />
                  }
                />
                <Route
                  path="asc-data-ingestion/new"
                  element={<AscriptionDataIngestionWorkflow />}
                />
                <Route
                  path="net-data-ingestion"
                  element={
                    <DataIngestion ingestionType="netDataIngestionDashboard" />
                  }
                />
                <Route
                    path="net-metadata-ingestion"
                    element={
                      <DataIngestion ingestionType="netMetaDataIngestionDashboard" />
                    }
                />
                <Route
                    path="weight-metadata-ingestion"
                    element={
                      <DataIngestion ingestionType="weightMetaDataIngestionDashboard" />
                    }
                />
                <Route
                  path="net-metadata-ingestion/new-metadata-ingestion"
                  element={<NetMetadataIngestionWorkflow />}
                />
                <Route
                  path="net-metadata-ingestion/new-net-var-calc-testrun"
                  element={<NetCalcTestRunWorkflow />}
                />
                <Route
                  path="net-data-ingestion/new-net-data-ingestion"
                  element={<NetDataIngestionWorkflow />}
                />
                <Route
                  path="net-data-ingestion/new-net-var-calculation"
                  element={<NetCalculationWorkflow />}
                />
                <Route
                    path="net-metadata-ingestion/new-net-meta-file-ingestion"
                    element={<NetMetaFileUploadWorkflow />}
                />
                <Route
                  path="weight-data-ingestion"
                  element={
                    <DataIngestion ingestionType="weightDataIngestionDashboard" />
                  }
                />
                <Route
                  path="weight-metadata-ingestion/new"
                  element={<WeightConfigIngestionWorkflow />}
                />
                <Route
                  path="weight-data-ingestion/new-weight-calc-snowflake"
                  element={<WeightCalcSnowflakeWorkflow />}
                />
                <Route
                  path="weight-data-ingestion/new-weight-calc-market"
                  element={<WeightCalcMarketFilesWorkflow />}
                />
                <Route
                  path="weight-data-ingestion/new-weight-calc-global"
                  element={<WeightCalcGlobalFilesWorkflow />}
                />
                <Route
                  path="export-data"
                  element={<DataIngestion ingestionType="exportDashboard" />}
                />
                <Route
                  path="export-data/new-export-market"
                  element={<ExportMarketWorkflow />}
                />
                <Route
                  path="export-data/new-export-global"
                  element={<ExportGlobalWorkflow />}
                />
                <Route
                  path="export-data/new-export-global-decoded"
                  element={<ExportGlobalDecodedWorkflow />}
                />
                <Route
                  path="export-data/new-batch-export-market"
                  element={<BatchExportWorkflow />}
                />
                <Route
                  path="export-data/new-export-spss"
                  element={<ExportSPSSWorkflow />}
                  />
                <Route
                  path="variable-order"
                  element={<DataIngestion ingestionType="variableOrder" />}
                />
                <Route
                  path="variable-order/new"
                  element={<VariableOrderUploadWorkflow />}
                />
                <Route
                    path="data-fix"
                    element={<DataFixWorkflow />}
                />
              </Route>

              <Route
                path="qa-home/*"
                element={
                  <AllureDockerMainContainer
                    darkState={this.state.darkState}
                    handleThemeChange={this.handleThemeChange}
                    isLogoutNeeded={this.state.isLogoutNeeded}
                    isSignInAnOption={this.state.isSignInAnOption}
                    getProjects={this.getProjects}
                  />
                }
              >
                <Route index element={<AllureDockerDashboard />} />
                <Route
                  path="qa-report-ui"
                  element={
                    <AllureDockerHomePage getProjects={this.getProjects} />
                  }
                />
                <Route
                  path="qa-report-ui/project1"
                  element={<AllureDockerFilesWorkflow />}
                />
                <Route
                  path="projects/:id"
                  element={
                    <AllureDockerProject
                      setAPIAlert={this.setAPIAlert}
                      getProjects={this.getProjects}
                    />
                  }
                />
                <Route
                  path="projects/:id/reports/:reportId"
                  element={<AllureDockerReportFullView />}
                />
              </Route>
            </Route>
            <Route
              path="/questionnaire-design/builder"
              element={<QuestionnaireBuilder />}
            ></Route>
            <Route
              path="/questionnaire-design/previewer"
              element={<QuestionnairePreviewerWidget />}
            ></Route>
            {signInAnOption}
            <Route element={<AllureDockerNotFound />} />
            {/*<Route path="questionnaire-design/*">*/}
            {/*  <Route index element={<QuestionnaireHomeHiddenPage />} />*/}
            {/*  <Route path="compare" element={<QuestionnaireComparePage />} />*/}
            {/*</Route>*/}
            <Route path="questionnaire-design/*">
              <Route index element={<QuestionnaireHomePage />} />
              <Route path="compare" element={<QuestionnaireComparePage />} />
            </Route>
          </Routes>
        );
      }
    }
    return (
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={darkTheme}>
            <BrowserRouter>{switchRouter}</BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    );
  }
}
