export const DataIngestionGridConstants = {
    rawDataIngestionDashboard: {
        welcomeLabel: "Welcome to Raw Data Ingestion",
        buttons: [
            {
                label: "Start New Raw Data Ingestion", navigateTo: "/data-processing/raw-data-ingestion/new"
            }
        ],
        dataTables: [
            {
                label: "Last Loaded Files",
                queryKey: "rawDataLoadFiles",
                apiEndPoint: "RawDataLoadFiles",
                columnConfig: "latestDataLoadTableConfig"
            },
            {
                label: "Rejection Table",
                queryKey: "rawDataRejectionFiles",
                apiEndPoint: "RawDataLoadRejectionFiles",
                columnConfig: "rejectionTableConfig"
            }
        ]
    },
    ascDataIngestionDashboard: {
        lastLoadedFilesQuery: "ascriptionDataLoadFiles",
        lastLoadedFilesEndpoint: "AscriptionDataLoadFiles",
        rejectionSummaryQuery: "ascriptionDataRejectionFiles",
        rejectionSummaryEndpoint: "AscriptionDataLoadRejectionFiles",
        welcomeLabel: "Welcome to Ascription Data Ingestion",
        buttons: [
            {
                label: "Start New Ascription Data Ingestion",
                navigateTo: "/data-processing/asc-data-ingestion/new"
            }
        ],
        dataTables: [
            {
                label: "Last Loaded Files",
                queryKey: "ascriptionDataLoadFiles",
                apiEndPoint: "AscriptionDataLoadFiles",
                columnConfig: "latestDataLoadTableConfig"
            },
            {
                label: "Rejection Table",
                queryKey: "ascriptionDataRejectionFiles",
                apiEndPoint: "AscriptionDataLoadRejectionFiles",
                columnConfig: "rejectionTableConfig"
            }
        ]
    },
    netMetaDataIngestionDashboard: {
        welcomeLabel: "Welcome to Net Metadata Variable Ingestion",
        dataTables: [
            {
                label: "Last Loaded Net YAML Config Files",
                queryKey: "netMetadataStatistics",
                apiEndPoint: "NetMetadataStatistics",
                columnConfig: "netMetaFilesTableConfig"
            },
            {
                label: " Current Net Config Files in DB",
                queryKey: "netMetadataFilesInfo",
                apiEndPoint: "NetMetadataFilesInfo",
                columnConfig: "netMetaFilesDBInfoTableConfig"
            },
        ],
        buttons: [
            {
                label: "Net Variable Test Run",
                navigateTo: "/data-processing/net-metadata-ingestion/new-net-var-calc-testrun"
            },
            {
                label: "Net Variable Metadata Ingestion",
                navigateTo: "/data-processing/net-metadata-ingestion/new-metadata-ingestion"
            },
            {
                label: "Net Variable Metadata: File",
                navigateTo: "/data-processing/net-metadata-ingestion/new-net-meta-file-ingestion"
            }
        ]
    },
    netDataIngestionDashboard: {
        welcomeLabel: "Welcome to Net Variable Ingestion",
        dataTables: [
            {
                label: "Last Calculated Markets",
                queryKey: "netDataCalculations",
                apiEndPoint: "NetDataCalculations",
                columnConfig: "netDataCalculationTableConfig"
            },
        ],
        buttons: [
            {
                label: "Net Variable Calculation",
                navigateTo: "/data-processing/net-data-ingestion/new-net-var-calculation"
            },
            {
                label: "Net Variable Ingestion : File",
                navigateTo: "/data-processing/net-data-ingestion/new-net-data-ingestion"
            }
        ]
    },
    weightMetaDataIngestionDashboard: {
        welcomeLabel: "Welcome to Weight Metadata Variable Ingestion",
        dataTables: [
            {
                label: "Last Loaded Weight Config Files",
                queryKey: "weightMetadataStatistics",
                apiEndPoint: "WeightMetadataStatistics",
                columnConfig: "weightMetaFilesTableConfig"
            },
            {
                label: " Current Weight Config Files in DB",
                queryKey: "weightMetadataFilesInfo",
                apiEndPoint: "WeightMetadataFilesInfo",
                columnConfig: "weightMetaFilesDBInfoTableConfig"
            },
        ],
        buttons: [
            {
                label: "Weight Config Files Ingestion",
                navigateTo: "/data-processing/weight-metadata-ingestion/new"
            }
        ]
    },
    weightDataIngestionDashboard: {
        welcomeLabel: "Welcome to Weight Calculation Step",
        dataTables: [
            {
                label: "Weight Snowflake to Snowflake Calculation Runs",
                queryKey: "weightSnowflakeToSnowflakeStatistics",
                apiEndPoint: "WeightSnowflakeToSnowflakeStatistics",
                columnConfig: "weightSnowflakeToSnowflakeRunConfig"
            },
            {
                label: "Weight Snowflake to Snowflake Calculation Key Value Table",
                queryKey: "weightSnowflakeToSnowflakeTable",
                apiEndPoint: "WeightSnowflakeToSnowflakeTable",
                columnConfig: "weightSnowflakeToSnowflakeTableConfig"
            },

        ],
        buttons: [
            {
                label: "Weight Calculation : Snowflake",
                navigateTo: "/data-processing/weight-data-ingestion/new-weight-calc-snowflake"
            },
            {
                label: "Weight Calculation : File (Market Level)",
                navigateTo: "/data-processing/weight-data-ingestion/new-weight-calc-market"
            },
            {
                label: "Weight Calculation : File (Global)",
                navigateTo: "/data-processing/weight-data-ingestion/new-weight-calc-global"
            }
        ]
    },
    exportDashboard: {
        welcomeLabel: "Welcome to Export Step",
        dataTables: [
            {
                label: "Snowflake Global Export Latest Runs",
                queryKey: "exportGlobalStatistics",
                apiEndPoint: "ExportGlobalStatistics",
                columnConfig: "exportStatisticsRunConfig"
            },
            {
                label: "Snowflake Markets Export Latest Runs",
                queryKey: "exportMarketsStatistics",
                apiEndPoint: "ExportMarketsStatistics",
                columnConfig: "exportStatisticsRunConfig"
            }
        ],
        buttons: [
            {
                label: "Export : Market",
                navigateTo: "/data-processing/export-data/new-export-market"
            },
            {
                label: "Batch Export : Markets",
                navigateTo: "/data-processing/export-data/new-batch-export-market"
            },
            {
                label: "Export : Global",
                navigateTo: "/data-processing/export-data/new-export-global"
            },
            {
                label: "Export : Decoded Global",
                navigateTo: "/data-processing/export-data/new-export-global-decoded"
            },
            {
                label: "Export : SPSS",
                navigateTo: "/data-processing/export-data/new-export-spss"
            }
        ]
    },
    variableOrder: {
        welcomeLabel: "Welcome to Variable Order Upload Step",
        dataTables: [
            {
                label: "Ordered Variables Statistics ",
                queryKey: "variableOrderingStatistics",
                apiEndPoint: "VariableOrderingStatistics",
                columnConfig: "variableOrderTableConfig"
            },
        ],
        buttons: [
            {
                label: "Upload Variable Ordering File",
                navigateTo: "/data-processing/variable-order/new"
            }
        ]
    }
}