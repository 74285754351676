import React, { useEffect, useState } from 'react';
import { WppInput } from '@platform-ui-kit/components-library-react';
import { useCookies } from 'react-cookie';
import { useDebounce } from 'use-debounce';

// Define the input event interface
interface InputChangeEventDetail {
    value: string;
}

interface WppInputCustomEvent<T> extends Event {
    detail: T;
}

type Props = {
    value: string;
    folderValidation: string;
    setfolderValidation: any;
    handleInputChange: (value: string) => void;
    errorMessage?: string;
    setErrorMessage: (message: string | undefined) => void;
};

export const ResultFolder: React.FC<Props> = ({
    value,
    handleInputChange,
    folderValidation,
    setfolderValidation,
    errorMessage,
    setErrorMessage,
}: Props) => {
    const [apiError, setApiError] = useState<string | null>(null);
    const [cookies] = useCookies(['csrf_access_token']);
    const [debouncedValue] = useDebounce(value, 500);
    const [validationError, setValidationError] = useState<string | null>(null);

  
    
    const handleChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {

        setErrorMessage('');
        errorMessage='';
        const newValue = event.detail.value;
        handleInputChange(newValue);
        validateInput(newValue);
      
    };
   

    const validateInput = (inputValue: string) => {
        let error = null;

        if (!inputValue) {
            error = "'id' should not be empty.";
            folderValidation = "'id' should noe be empty.";
        } else if (inputValue.length > 100) {
            error = "'id' should not contain more than 100 characters.";
            folderValidation = "'id' should not contain more than 100 characters.";
        } else {
            const projectIdPattern = /^[a-z\d]([a-z\d -]*[a-z\d])?$/;
            if (!projectIdPattern.test(inputValue)) {
                error = "'id' should contain alphanumeric lowercase characters or hyphens. For example: 'my-project-id'.";
                folderValidation = "'id' should contain alphanumeric lowercase characters or hyphens. For example: 'my-project-id'.";
            
            } else if (inputValue === 'default') {
                error = "The id 'default' is not allowed. Try with another project ID.";
                folderValidation = "The id 'default' is not allowed. Try with another project ID.";
            }
        }

        setValidationError(error);
        setfolderValidation(error ? 'invalid' : 'valid'); // Update folder validation state
    };

    const displayError =  errorMessage  &&  validationError ;

    return (
        <div>
            <div>Create Folder</div>
            <WppInput
                id="resultFolder"
                name="resultFolder"
                style={{ width: '300px' }}
                value={value}
                onWppChange={handleChange}
                placeholder="Enter Result Folder Name"
            />
            {displayError && <p style={{ color: 'red' }}>{displayError}</p>}
        </div>
    );
};
