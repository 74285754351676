import React, { useState, useEffect } from 'react';
import {
  WppStepper,
  WppStep,
  WppTopbar,
  WppButton,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/DataProcessing/Dashboard/data-ingestion-workflow.module.scss';
import { InstructionStep } from './InstructionStep';
import { SurveySelectStep } from './SurveySelectStep';
import { ResultFolder } from './ResultFolder';
import { UploadFilesStep } from '../../components/DataProcessing/Common/UploadFilesStep';
import { WeightDagConstants } from '../../components/DataProcessing/WeightVariableIngestion/WeightDagConstants';
import { TriggerDag } from './ResultFoldercopy';
import { AppConfig } from '../../AppConfig';
import { useCookies } from 'react-cookie';
import axios from '../../api/axios-allure-docker';
import { AxiosRequestConfig } from 'axios';
import { useDebounce } from 'use-debounce'; // Import useDebounce from use-debounce


const WORKFLOW_STEPS = 5;

export const AllureDockerFilesWorkflow: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [highestStep, setHighestStep] = useState(1);
  const [isDagSuccess, setIsDagSuccess] = useState(false);
  const [resultFolderError, setResultFolderError] = useState<string>('');
  const [testSuite, setTestSuite] = useState<string>('');
  const [folderValidation, setFolderValidation] = useState<string>('');
  const [selectionPanel, setSelectionPanel] = useState<string>('');
  const [fileNamingConventionStepChecked, setFileNamingConventionStepChecked] = useState<boolean>(false);
  const [isFileNamingConventionStepPristine, setIsFileNamingConventionStepPristine] = useState<boolean>(true);
  const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState<boolean>(false);
  const [isUploadFilesStepPristine, setUploadFilesStepPristine] = useState<boolean>(true);
  const [surveySelectError, setSurveySelectError] = useState<string>('');
  const [isResultFolderValid, setIsResultFolderValid] = useState<boolean>(true); // New state
  const [valuefromapi, setValuefromapi] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [cookies] = useCookies(['csrf_access_token']); // Use react-cookie to manage cook
  

  const [debouncedValue] = useDebounce(testSuite, 500); // Debounce delay in milliseconds


  const handleNextStep = async () => {
    if (currentStep === WORKFLOW_STEPS) {
      return;
    }
  
    if (currentStep === 1 && !fileNamingConventionStepChecked) {
      setIsFileNamingConventionStepPristine(false);
      return;
    }
  
    if (currentStep === 2) {
      // Make the API call to validate the Result Folder before checking the conditions
      const csrfToken = cookies.csrf_access_token;
      const accessToken = localStorage.getItem('access_token');
      localStorage.setItem('csrf_access_token', csrfToken);
      const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken || '',
        },
    };
  
      try {
        const response = await axios.get(`${AppConfig.alluerDockerApiUrl}/projects/${debouncedValue}`, config);

      
        const message =response.data.meta_data?.message || '';
        var code ='';
      
        if (message.includes('project_id') && message.includes('not found')) {
           // folderValidation == 'Name Available';
            code = '1';


        } else if (message.includes('Project successfully obtained')) {
          //  folderValidation =='Name already exists, please select a new one.';
            code= '2'

        } 
    }  catch (error) {
        if (error.message.includes('project_id') && error.message.includes('not found')) {
            code = '1';
        } else if (error.message.includes('Projects successfully obtained')) {
            code = '2';
        } 

    }
};

//     // After the API call, check conditions based on the value from API
    if (currentStep === 2) {
if (!testSuite.trim() || folderValidation!='valid' || code == '2') {
          setResultFolderError('Name already exists');
          return;
  }
else if (!testSuite.trim() || folderValidation!='valid' ||  code == '1') {
    setResultFolderError('');
}

    };



     
  
    if (currentStep === 3) {
      if (selectionPanel.trim() === '') {
        setSurveySelectError('Please select a value for the SurveySelect.');
        return;
      } else if (selectionPanel === 'db_checks' || selectionPanel === 'global_view_checks') {
        setSurveySelectError('');
        setCurrentStep(5);
        return;
      }
    }
  
    if (currentStep === 4 && !uploadFilesStepChecked) {
      setUploadFilesStepPristine(false);
      return;
    }
  
    // Proceed to the next step
    setCurrentStep((prevStep) => {
      const step = prevStep + 1;
      if (step > highestStep) {
        setHighestStep(step);
      }
      return step;
    });
  };
  

  const handlePreviousStep = () => {
    if (currentStep === 1) return;

    if (currentStep === 5 && (selectionPanel === 'db_checks' || selectionPanel === 'global_view_checks')) {
      setCurrentStep(currentStep - 2);
      return;
    }

    setCurrentStep((prevStep) => prevStep - 1);
  };

  const onDagSuccess = () => {
    setIsDagSuccess(true);
  };

  const handleInputChange = (value: string) => {
    setTestSuite(value);
    //setFolderValidation(value);
  };

  const handleSurveyChange = (value: string) => {
    setSelectionPanel(value);
  };

 


  

const getSelectedPage = () => {
    switch (currentStep) {
      case 1:
        return (
          <InstructionStep
            checked={fileNamingConventionStepChecked}
            onChange={setFileNamingConventionStepChecked}
            title="Please read the instructions below to proceed with the Data Quality Check Panel:"
            links={[
              {
                label: 'Confluence Wiki',
                link: 'https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337351712940/Audience+Origin+Data+Quality+Automation',
                context: 'Find more details here',
              },
            ]}
            context={['This is used for Data Quality Checks.']}
            checkboxText="I have read and understood the instructions."
          />
        );
      case 2:
        return (
          <ResultFolder
            value={testSuite}
            folderValidation={folderValidation}
            setfolderValidation={setFolderValidation}
            handleInputChange={handleInputChange}
            errorMessage={resultFolderError}
            setErrorMessage={setErrorMessage}

          />
        );
      case 3:
        return (
          <SurveySelectStep
            value={selectionPanel}
            onValueChange={handleSurveyChange}
            errorMessage={surveySelectError}
          
          />
        );
      case 4:
        return (
          <UploadFilesStep
            checked={uploadFilesStepChecked}
            onChange={setUploadFilesStepChecked}
            title="Upload SPSS Files for weight market file calculation:"
            links={[
              {
                label: 'Confluence Wiki',
                link: WeightDagConstants.weightMarketFileCalculationWikiLink,
                context: 'Find detailed instruction for weight market file calculation here',
              },
              {
                label: 'GCS Location',
                link: `${AppConfig.ingestionBucketUrl}/qa/output_files`,
                context: 'Clean folder and upload prepared spss files here',
              },
              {
                label: '',
                link: ``,
                context: 'If More than 20 files please directly upload files in the GCS by using link above',
              }
            ]}
            checkboxText="I have uploaded the config files."
          />
        );
      case 5:
        return (
          <TriggerDag
            testSuite={selectionPanel}
            projectId={testSuite}
            onDagSuccess={onDagSuccess}
          //  id={testSuite}
          />
        );
      default:
        return <div><h3>Page {currentStep}</h3></div>;
    }
  };

  const handleStepClick = (event: CustomEvent) => {
    if (isDagSuccess) return;

    const step = event.detail.index;
    if (step <= highestStep) {
      setCurrentStep(step);
    }
  };

  const navigateToMIHome = () => {
    navigate('/qa-home/qa-report-ui');
  };

  return (
    <div>
      <WppTopbar navigation={[]} style={{ margin: '1rem' }}>
        <div slot="app">
          <WppTypography type="l-midi" style={{ width: '20rem' }}>
            Data Quality Check Panel
          </WppTypography>
        </div>
      </WppTopbar>
      <div style={{ display: 'flex', margin: '2rem' }}>
        <WppStepper activeStep={currentStep} style={{ width: '19rem' }}>
          <WppStep
            onWppStepChange={handleStepClick}
            error={!fileNamingConventionStepChecked && !isFileNamingConventionStepPristine}
          >
            <p slot="label">Instructions</p>
          </WppStep>
          <WppStep onWppStepChange={handleStepClick}>
            <p slot="label">Result Folder</p>
          </WppStep>
          <WppStep onWppStepChange={handleStepClick}>
            <p slot="label">Test Suite</p>
          </WppStep>
          {selectionPanel === 'db_file_checks' && (
            <WppStep
              onWppStepChange={handleStepClick}
              error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
            >
              <p slot="label">Upload Files</p>
            </WppStep>
          )}
          <WppStep onWppStepChange={handleStepClick}>
            <p slot="label">Trigger DAG</p>
          </WppStep>
        </WppStepper>
        <div className={styles['stepper-container']}>{getSelectedPage()}</div>
      </div>

      <div style={{ display: 'flex', margin: '2rem', justifyContent: 'flex-end' }}>
        {isDagSuccess ? (
          <WppButton variant="secondary" onClick={navigateToMIHome}>
            Go To Data Quality Check Panel Home
          </WppButton>
        ) : (
          <>
            <WppButton
              variant="secondary"
              onClick={handlePreviousStep}
              disabled={isDagSuccess}
              style={{ marginRight: '1rem' }}
            >
              Previous Step
            </WppButton>
            <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
              Next Step
            </WppButton>
          </>
        )}
      </div>
    </div>
  );
};
