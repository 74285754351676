import { AxiosInstance } from "axios";
import apiHelper from "./apiHelper";
import { AppConfig } from "../AppConfig";

//const QUESTIONNAIRE_BASE_URL = `http://localhost:9292/audience-origin-questionnaire/v1`;
//const QUESTIONNAIRE_BASE_URL = `http://localhost:8010/proxy/audience-origin-questionnaire/v1`
const QUESTIONNAIRE_BASE_URL = `${AppConfig.semanticSearchApiUrl}`;

enum EndPoints {
    Search = '/search',
    Create = '/create',
    AnswerQuestion = '/answer-question'
}

const request: AxiosInstance = apiHelper(QUESTIONNAIRE_BASE_URL)

export { request as SemanticSearchRequest, EndPoints as SemanticSearchEndPoints };

